require('./bootstrap');

// Adding jquery + jqueryui + drag and drop plugins
import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/droppable.js';
import 'jquery-ui/ui/widgets/sortable.js';


$( ".sortable" ).sortable();